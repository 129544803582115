<template>
	<div style="width: 100%;">
		<div class="">
			<!-- <div class="d-flex a-center" style="background: #f3f3f3;">
				<img class="m-1" src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dcde737189f.jpg"  style="width: 27px;height: 27px;border: 3px solid #ffffff;border-radius: 10px;"/>
				<div style="color: #0075f3;">Model：GPT-4.0</div>
			</div> -->
			<div ref='returnTop' class="neirong" :style="conheight" style="overflow-x:hidden;">
				<div v-for="(item,index) in dhlist" :key="index" >
					<!-- 用户 -->
					<div class="d-flex py-2" v-if="item.sfai == 0">
						<div style="margin-left: auto;width: 100%;background: rgb(255, 255, 255,0.6);border-radius: 10px;" class="d-flex">
							<div style="margin-top: 10px;margin-bottom: 10px;margin-left: 10px;">
								<div style="font-size: 14px;line-break: anywhere;">{{item.question}}</div>
							</div>
							<div style="margin-left: auto;margin-top: 10px;margin-bottom: 10px;margin-right: 10px;">
								<img class="m-1" src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dcc6c726c5b.jpg"  style="width: 34px;height: 34px;border: 2px solid #ffffff;border-radius: 10px;"/>
							</div>
						</div>
						
					</div>
					<!-- ai回复 -->
					<div class="py-2" v-else>
						<div class="d-flex" style="margin-right: 20px;">
							<div>
								<img class="m-1" src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dcde4def2e5.jpg"  style="width: 34px;height: 34px;border: 2px solid #ffffff;border-radius: 10px;"/>
							</div>
							<div style="margin-left: 10px;margin-top: 10px;margin-bottom: 10px;" v-if="dhlist.length - 1 == index" >
								<div style="white-space: pre-wrap;text-align: justify;font-size: 14px; width: 100%;line-break: anywhere;" v-html="item.answer" id="content"></div>
							</div>
							<div style="margin-left: 10px;margin-top: 10px;margin-bottom: 10px;" v-else>
								<div style="white-space: pre-wrap;text-align: justify;font-size: 14px; width: 100%;line-break: anywhere;" v-html="item.answer"></div>
							</div>
						</div>
						<div class="d-flex mt-2 mb-2" v-if="dhlist.length - 1 == index">
							<div style="width: 80px;height: 24px;border-radius: 4px;box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.5);margin-left: 50px;background: #ffffff;" class="d-flex a-center j-center" @click="cxhd(item)">
								<div style="color: #0075f3;font-size: 10px;">重新回答</div>
							</div>
							<div style="width: 98px;height: 24px;border-radius: 4px;box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.5);background: #ffffff;" class="d-flex a-center j-center ml-2" @click="lxdhsz(item)">
								<div style="color: #0075f3;font-size: 10px;" v-if="lxdh == 0">开启连续对话</div>
								<div style="color: #0075f3;font-size: 10px;" v-else>关闭连续对话</div>
							</div>
							<div style="width: 80px;height: 24px;border-radius: 4px;box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.5);background: #ffffff;" class="d-flex a-center j-center ml-2" @click="exportPDF(item)">
								<div style="color: #0075f3;font-size: 10px;">导出PDF</div>
							</div>
						</div>
						<!-- <div class="d-flex mt-2 mb-2" v-else>
							<div style="width: 80px;height: 24px;border-radius: 4px;box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.5);margin-left: 50px;background: #ffffff;" class="d-flex a-center j-center" @click="coptext(item.answer)">
								<div style="color: #0075f3;font-size: 10px;">复制</div>
							</div>
						</div> -->
					</div>
				</div>
				<div v-if="ddz == 1">
					<div class="d-flex py-2">
						<div>
							<img class="m-1" src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dcde4def2e5.jpg"  style="width: 34px;height: 34px;border: 3px solid #ffffff;border-radius: 10px;"/>
						</div>
						<div style="margin-right: 2%;">
							<img class="m-1" src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dcdec42f115.gif"  style="width: 34px;height: 34px;"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="">
			<div class="w-100">
				<div style="width: 94%;box-shadow: 0 2px 20px 5px rgba(0, 0, 0, 0.2);margin: 0 auto;height: 36px;border-radius: 10px;background: #ffffff;" @click="" class="d-flex a-center">
					<div class="" style="width: 86%;margin-left: 2%;margin-right: 2%;">
						<input type="text" v-model="question" placeholder="请输入您的问题" class="intxt" maxlength="500" style="width: 100%;font-size: 14px;"/>
					</div>
					<div style="width: 8%;margin-right: 2%;" @click="tiwen">
						<div style="float: right;">
							<img src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dcde60ba39f.png" style="height: 30px;"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import clipboard from 'clipboard';
	import html2Canvas from 'html2canvas'
	import JsPDF from 'jspdf'
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	// 代码优化
	import MarkdownIt from 'markdown-it'
	import mdKatex from '@traptitech/markdown-it-katex'
	import hljs from 'highlight.js';
	
	const mdi = new MarkdownIt({
		linkify: true,
		highlight(code, language) {
			const validLang = !!(language && hljs.getLanguage(language))
			
			if (validLang) {
				const lang = language ?? ''
				return 	`<pre class="pre-code-box"><div class="pre-code-header"><span class="code-block-header__lang">`+lang+`</span><span class="code-block-header__copy">复制代码</span></div><div class="pre-code"><code class="hljs code-block-body `+lang+`">`+hljs.highlight(lang, code, true).value+`</code></div></pre>`

			}
			return `<pre class="pre-code-box"><div class="pre-code-header"><span class="code-block-header__lang"></span><span class="code-block-header__copy">复制代码</span></div><div class="pre-code"><code class="hljs code-block-body">`+hljs.highlightAuto(code).value+`</code></div></pre>`
		}
	})
	mdi.use(mdKatex, {
		blockClass: 'katexmath-block rounded-md p-[10px]',
		errorColor: ' #cc0000'
	})
	
	export default {
		data() {
			return {
				// 打字机速度
				shudu:50,
				htmlStr:'',
				question:'',
				dhlist:[],
				aiheight:0,
				scrollTop:0,
				ddz:0,
				lxdh:0,
				conversation_id:null,
				conheight:{
					height:''
				},
				myjf:0
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user,
				token: state => state.user.token
			}),
		},
		// 生命周期
		created() {
			this.__init()
			window.addEventListener('resize',this.getHeight);
			this.getHeight()
			document.title = '问问GPT'
		},
		methods: {
			getHeight(){
				let num = window.innerHeight - 150
				this.conheight.height = num +'px';
			},
			__init() {
				this.axios.get('/admin/kcdh/list/'+this.user.id, {
					token: true
				}).then(res => {
					let data = res.data.data
					this.dhlist = this.tabless(data)
					this.$nextTick(() => {
						this.$refs.returnTop.scrollTop = 1000 * this.dhlist.length
					})
				})
			},
			tiwen(){
				if (this.token == false) {
					this.$router.push({ name: 'login' });
				}else{
					this.axios.get('/admin/myuser/'+this.user.id, {
						token: true
					}).then(res => {
						let data = res.data.data
						this.myjf = data.jf
						if(this.myjf == 0){
							this.$message({
								message: '可使用次数不足，请补充后再使用',
								type: 'warning'
							});
						}else{
							this.Totop()
							let data = {
								user_id: this.user.id,
								question: this.question,
								stateful:'true',
								conversation_id:this.conversation_id,
								sfai:0,
								list_id:0,
								fl_id:0
							}
							this.dhlist.push(data)
							this.ddz = 1
							this.axios.post('/admin/kcdh',data,{ token:true }).then(res => {
								this.dhlist.push(res.data.data)
								this.dhlist = this.tabless(this.dhlist)
								this.ddz = 0
								this.setData(this.dhlist.slice(-1)[0])
							});
							this.question = ''
						}
					})
					
					
				}
				
			},
			setData(e){
				let i = 0;
				let textWriter = e.answer
				let that = this
				let timer = setInterval(function(){
					if (i <= textWriter.length) {
					    that.dhlist.slice(-1)[0].answer = textWriter.slice(0, i++) + '_'
					}else {
						that.dhlist.slice(-1)[0].answer = textWriter
						clearInterval(timer)
					}
				}, this.shudu)
			},
			Totop(){
				this.scrollTop = 1000 * this.dhlist.length //这里乘1000不是固定的，乘1000是肯定到底部了
			},
			lxdhsz(e){
				if(this.lxdh == 0){
					this.lxdh = 1
					this.conversation_id = e.conversation_id
				}else{
					this.lxdh = 0
					this.conversation_id = null
				}
			},
			cxhd(e){
				this.axios.get('/admin/myuser/'+this.user.id, {
					token: true
				}).then(res => {
					let data = res.data.data
					this.myjf = data.jf
					if(this.myjf == 0){
						this.$message({
							message: '可使用次数不足，请补充后再使用',
							type: 'warning'
						});
					}else{
						this.Totop()
						let data = {
							user_id: this.user.id,
							question: e.question,
							stateful:'true',
							conversation_id:this.conversation_id,
							sfai:0,
							list_id:0,
							fl_id:0
						}
						this.ddz = 1
						this.axios.post('/admin/kcdh/cfhd',data,{ token:true }).then(res => {
							this.dhlist.push(res.data.data)
							this.dhlist = this.tabless(this.dhlist)
							this.ddz = 0
							this.setData(this.dhlist.slice(-1)[0])
						});
					}
				})
				
			},
			//去空
			trimSpace(array) {
			    for (var i = 0; i < array.length; i++) {
			        if (array[i] == "" || array[i] == null || typeof (array[i]) == "undefined") {
			            array.splice(i, 1);
			            i = i - 1;
			        }
			    }
			    return array;
			},
			// 表格生成
			tabless(e){
				e.map(i => {
					let des = i.answer
					if(des != null){
						if(des.indexOf('|') !== -1){
							let newdes = des.replace(/\n/g,"<br/>")
							let newdesa = newdes.split('<br/>')
							// 第一句话
							let toubu = '<div style="margin-bottom: 10px;">'+newdesa[0]+'</div>'
							newdesa.shift()
							newdesa.shift()
							// 最后一句话
							let weibu = '<div style="margin-top: 10px;">'+newdesa[newdesa.length-1]+'</div>'
							newdesa.pop()
							newdesa.pop()
							// 头部列表
							let taba = this.trimSpace(newdesa[0].split('|'))
							let tablea = ''
							for(let d of taba){
								tablea += '<th>'+d+'</th>'
								
							}
							tablea = '<thead><tr>'+tablea+'</tr></thead>'
							newdesa.shift()
							newdesa.shift()
							// 内容列表
							let tabb = newdesa.map( c =>{
								return this.trimSpace(c.split('|'))
							})
							let tableb = ''
							for(let cc of tabb){
								let newcc = ''
								for(let ccc of cc){
									newcc += '<td>'+ccc+'</td>'
								}
								newcc = '<tr style="background: #fff;">'+newcc+'</tr>'
								tableb += newcc
							}
							tableb = '<tbody>'+tableb+'</tbody>'
							let newlist = toubu+'<table>'+tablea+tableb+'</table>'+weibu
							i.answer = newlist
						}else if(des.indexOf('```') !== -1){
							i.answer = this.dmyh(i.answer)
						}
					}
					return i
				})
				return e
			},
			// 导出pdf
			exportPDF(e){
				var title = document.querySelector('#content')
				html2Canvas(title, {
					allowTaint: true
				}).then(function (canvas) {
			
				    let contentWidth = canvas.width
				    let contentHeight = canvas.height
				    let pageHeight = contentWidth / 592.28 * 841.89
				    let leftHeight = contentHeight
				    let position = 0
				    let imgWidth = 595.28
				    let imgHeight = 592.28 / contentWidth * contentHeight
				    let pageData = canvas.toDataURL('image/jpeg', 1.0)
				    let PDF = new JsPDF('', 'pt', 'a4')
					
				    if (leftHeight < pageHeight) {
						PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
				    } else {
						while (leftHeight > 0) {
							PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
							leftHeight -= pageHeight
							position -= 841.89
							if (leftHeight > 0) {
								PDF.addPage()
							}
						}
				    }
				    console.log(PDF.save('内容下载.pdf'))
					PDF.save('内容下载.pdf')
				})
			},
			
			// 格式优化
			dmyh(value){
				return mdi.render(value)
			},
		}
	}
</script>

<style>
	.el-main{
		padding: 8px;
	}
	.a-center{
		align-items: center;
	}
	.j-center{
		justify-content: center;
	}
	input{
	    border:none;
	}
	pre {
	  white-space: pre-wrap;
	  word-wrap: break-word;
	}
	.neirong{
		overflow-y:scroll;
	}
	::-webkit-scrollbar {
		height: 0;
		width: 0;
		color: transparent;
	}
	table {
	    border: 1px solid #ccc;
	    width: 100%;
	    margin-bottom: 10px;
	}
	table th {
		font-size: 10px;
	    text-align: center;
	    background: #dee8ee;
	}
	table td, table th {
		font-size: 10px;
	    border-bottom: 1px solid #ccc;
	    border-right: 1px solid #ccc;
	    padding: 6px 13px;
	}
</style>
